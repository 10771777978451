<template>
  <div>
    <div v-if="payload?.title" class="grid justify-center items-center mb-2 md:mb-10">
      <div class="text-base order-1 text-center" v-html="payload?.subTitle" v-if="payload?.subTitle"></div>
      <div class="text-2xl md:text-3xl order-1 text-center">
        {{ payload?.title }}
      </div>
    </div>

    <div class="relative" :class="[payload.customClass]">
      <div
          ref="container"
          class="keen-slider"
      >
        <div
            :key="`styb-${idx}`"
            class="keen-slider__slide"
            v-for="(i, idx) in payload.content"
            @mouseover="setHoverImage(i)"
            @mouseleave="setHoverImage('')"
        >
          <NuxtLinkLocale
              :to="i.ctaLink ? checkAndAddSlash(i.ctaLink) : '#'"
              class="relative w-full overflow-hidden h-full block "
          >
            <NuxtImg
                :src="getImageForDevice(i)"
                :alt="i.title"
                class="z-0 object-cover"
                loading="lazy"
                format="webp"
                placeholder
                :quality="80"
                sizes="xs:150px md:300px lg:512px"
                :style="
              hoverImage === i
                ? {
                    transform: 'scale(1.10)',
                    transition: 'transform 200ms ease-in-out',
                    overflow: 'hidden',
                  }
                : {
                    transform: 'scale(1)',
                    transition: 'transform 200ms ease-in-out',
                    overflow: 'hidden',
                  }
            "
            />

            <div class="absolute inset-0 z-30 flex w-full h-full group"
                 :class="{[backdropClass] : payload.config?.text_backdrop_enable}">
              <div
                  class="relative z-30 w-full text-white sm:py-6 md:py-10 flex flex-col items-start justify-end space-y-2 pl-3.5 sm:pl-8 sm:pr-4"
              >
                <div :class="titleClass">
                  {{ i.title }}
                </div>

                <div :class="subTitleClass">
                  {{ i.subTitle }}
                </div>

                <div
                    class="py-2.5 px-4 w-32 text-center border-2 border-transparent font-bold text-base uppercase group-hover:bg-primary-500 text-white group-hover:border-primary backdrop-blur-md bg-primary/60 rounded-full"
                    v-if="i.ctaText && i.config?.isCtaShow"
                >
                  {{ i.ctaText }}
                </div>
              </div>
              <div
                  class="absolute sm:relative right-0 sm:right-auto top-0 sm:top-auto flex justify-end items-end py-2.5 sm:py-12 md:py-10 pr-2 sm:pr-8 sm:pl-4">
                <div
                    class="border rounded-full size-10 sm:size-12 flex items-center justify-center"
                    :class="
                  hoverImage === i
                    ? 'bg-primary text-white border-primary'
                    : 'bg-white text-primary'
                "
                >
                  <ArrowRightIcon class="w-5 h-5"/>
                </div>
              </div>
            </div>
          </NuxtLinkLocale>
        </div>
      </div>

      <UINavigationArrows :slider="slider" :current="current" v-if="props.payload.config?.show_navigation_arrows"/>
    </div>
  </div>
</template>

<script setup>
import {ArrowRightIcon} from "@heroicons/vue/24/outline";

const props = defineProps(["payload"]);

const breakpoints = computed(() => {
  return {
    '(min-width: 767px)': {
      slides: {
        perView: parseFloat(props.payload.config?.desktop_breakpoint) > 0 ? parseFloat(props.payload.config?.desktop_breakpoint) : 3,
        spacing: 16,
      }
    },
    '(min-width: 320px and max-width: 767px)': {
      slides: {
        perView: parseFloat(props.payload.config?.tablet_breakpoint) > 0 ? parseFloat(props.payload.config?.tablet_breakpoint) : 2.5,
        spacing: 16,
      }
    },
    '(max-width: 768px)': {
      slides: {
        perView: parseFloat(props.payload.config?.mobile_breakpoint) > 0 ? parseFloat(props.payload.config?.mobile_breakpoint) : 1.5,
        spacing: 16,
      }
    },
  }
});

const {autoPlayPlugin} = useSlider()
const current = ref(1)

const [container, slider] = useKeenSlider({
  initial: current.value,
  created: (s) => {
    current.value = s.track.details.rel
  },
  slideChanged: (s) => {
    current.value = s.track.details.rel
  },
  loop: false,
  breakpoints: breakpoints.value,
  slides: {perView: 5},
}, [
  autoPlayPlugin
])

const hoverImage = ref("");

const setHoverImage = (i) => {
  hoverImage.value = i;
};

const backdropClass = computed(() => props.payload.config?.text_backdrop_class ? props.payload.config?.text_backdrop_class : 'bg-gradient-to-t from-primary/50 via-transparent to-transparent')
const titleClass = computed(() => props.payload.config?.title_class ? props.payload.config?.title_class : 'text-white text-xl font-medium')
const subTitleClass = computed(() => props.payload.config?.sub_title_class ? props.payload.config?.sub_title_class : 'text-primary text-sm w-1/2')

</script>
